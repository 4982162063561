@use 'colors' as colors;

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  scroll-behavior: smooth;
}

html {
  scroll-padding-top: 70px;
}

body {
  margin: 0 auto;
  max-width: 1290px;
  background-color: colors.$main-background-color;
}