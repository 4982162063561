@use 'colors' as colors;


#banner {
  background-image: url('../assets/bmm_background.webp');
  background-color: black;
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .bmm-logo-container {
    position: relative;
    top: 46px;
    z-index: 1;
  }
  

  .banner-title {
    font-size: 1.6em;
    background-color: rgba(0,0,0, 0.7);
    padding: 24px;
    border-radius: 24px;
    border: 1px solid colors.$nav-highlighted-text;
    color: white;
    p {
      text-align: center;
      font-size: 2em;
      font-weight: 300;
    }

    .smallprint {
      font-size: 24px;
    }
  }
}



@media only screen and (max-width: 980px) {
  #banner {
  .banner-title {
    padding: 16px;

    p {
      font-size: 1.5em;
    }

  }
}
}