.image-carousel {
  margin: 32px 0px;

  img {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
    height: auto;
  }

  .slick-next {
    right: 40px;
    &::before {
      font-size: 40px !important;
    }
  }

  .slick-prev {
    left: 20px;
    z-index: 1;
    &::before {
      font-size: 40px !important;
    }
  }

}

.dots-style {
  button::before {
    color: white !important;
  }
}