@use 'colors' as colors;

a {
  text-decoration: none;
}

header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: colors.$background-color;
  padding: 8px 24px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  font-weight: 300;
  font-size: 18px;
  color: white;
  z-index: 2;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1290px;
  margin: auto;

  .logo-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    p {
      font-size: 16px;
    }
  }

  aside {
    display: flex;
    flex-direction: row;
    gap: 24px;

  }
}

a, a:visited {
  color: white;
}

a:hover {
  color: colors.$nav-highlighted-text;
}

.menu-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: white;

  &:hover {
    border: 1px solid colors.$nav-highlighted-text;
  }
}

.dropdown-list-links {
  display: absolute;
  top: 40px;
  z-index: 1;
  background-color: colors.$background-color;
  animation: fadeIn ease 2s;
  
  .links-dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 8px 0px 16px 0px
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



@media only screen and (max-width: 980px) {
  .links {
    display: none;
  }
}