@use 'colors' as colors;

.App {
  background-color: colors.$background-color;
  padding: 8px 108px;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 980px) {
  .App {
    padding: 8px 16px;
  }
}