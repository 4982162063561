@use 'colors' as colors;

main {
  color: white;
  padding: 16px;
  background-color: colors.$main-background-color;
  border-radius: 16px;
  border: 1px solid colors.$nav-highlighted-text;
  margin-top: 16px;
  padding: 48px 96px;

  p, strong, li {
    font-size: 24px;
  }

  p {
    white-space: pre-line;
  }

  h2 {
    font-weight: 300;
    font-size: 36px;
    color: colors.$nav-highlighted-text;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 1px;
  }

  .concerts-container {
    border-top: 1px solid colors.$nav-highlighted-text;
    margin-top: 34px;
    
    p {
      font-size: 24px;
    }
  }

  .about-container {
    border-top: 1px solid colors.$nav-highlighted-text;
    margin-top: 34px;
    padding-bottom: 16px;
    text-align: justify;
    text-justify: inter-word;
    border-bottom: 1px solid colors.$nav-highlighted-text;
  }

  .history-container {
    
    text-align: justify;
    text-justify: inter-word;
    padding-bottom: 16px;
    border-bottom: 1px solid colors.$nav-highlighted-text;
  }
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.join-bmm-btn {
  background-color: colors.$nav-highlighted-text;
  font-size: 16px;
  border-radius: 16px;
  padding: 8px 16px;
  border: none;
  a {
    color: black !important;
    font-size: 24px;
    text-align: center;
  }

  &:hover {
    border: 1px solid white;
    margin-bottom: -2px;
  }
}

.map-container {
  width: 75%;
  margin: auto;
}
#bmmMap {
  width: 100%;
  height: 400px;
}

.profiles-container {
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin-top: 64px;

  a {
    text-decoration: underline;
  }
}

.video-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  
  iframe {
    margin-top: 16px;
    border: none;
    border-radius: 8px;
    width: 100%;
    aspect-ratio: 16/9;
    height: auto;
  }
}

.profile-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 64px;  
  img {
    border-radius: 16px;
    width: 182px;
    height: auto;
  }
}

@media only screen and (max-width: 980px) {
  main {
    padding: 16px;

    p, strong, li {
      font-size: 18px;
    }

    .concerts-container {

      h2 {
        font-size: 28px;
      }
  
      p {
        font-size: 18px;
      }
      
    }
  }

  .map-container {
    width: 100%;
  }

  

  

  #bmmMap {
    width: 100%;
    height: 320px;
  }

  .profile-row {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}